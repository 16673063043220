import logo1 from '../img/logo1.png';
import logo2 from '../img/logo2.png';
import logo3 from '../img/logo3.png';
import logo4 from '../img/logo4.png';
import logo5 from '../img/logo5.png';
import logo6 from '../img/logo6.png';
import logo7 from '../img/logo7.png';
import logo8 from '../img/logo8.png';
import logo9 from '../img/logo9.png';
import logo10 from '../img/logo10.png';
import logo11 from '../img/logo11.png';
import logo12 from '../img/logo12.png';
import logo13 from '../img/logo13.png';
import logo14 from '../img/logo14.png';
import logo15 from '../img/logo15.png';
import logo16 from '../img/logo16.png';
const Companies = () => {
  return (
    <section id='how' className=' bg-light p-5 animation '>
      <div className='company-heading'>
        <h1 className='text-dark display-4 py-5  '>
          Findtrend helps you to increase your productivity and reduce your
          computer's memory load,
          <span className='text-secondary'>
            an application that can fulfill your daily browsing needs.
          </span>
        </h1>
      </div>
      <h1 className=' company-heading2 text-dark display-4 py-5 text-center '>
        Findtrend make +1000 Startup grow
      </h1>
      <div className='container  p-4'>
        <div className='row my-4 gap-2 '>
          <div className='company-logo col  col-6 col-xl-3  '>
            <img
              className='img-fluid mx-auto d-block'
              src={logo1}
              alt='images'
            />
          </div>
          <div className='company-logo col  col-6 col-xl-3  '>
            <img
              className='img-fluid mx-auto d-block'
              src={logo2}
              alt='images'
            />
          </div>
          <div className='company-logo col  col-6 col-xl-3  '>
            <img
              className='img-fluid mx-auto d-block'
              src={logo3}
              alt='images'
            />
          </div>
          <div className='company-logo col  col-6 col-xl-3  '>
            <img
              className='img-fluid mx-auto d-block'
              src={logo4}
              alt='images'
            />
          </div>
        </div>
        <div className='row my-4 gap-2'>
          <div className='company-logo col  col-6 col-xl-3  '>
            <img
              className='img-fluid mx-auto d-block'
              src={logo5}
              alt='images'
            />
          </div>
          <div className='company-logo col  col-6 col-xl-3 '>
            <img
              className='img-fluid mx-auto d-block'
              src={logo6}
              alt='images'
            />
          </div>
          <div className='company-logo col  col-6 col-xl-3 '>
            <img
              className='img-fluid mx-auto d-block'
              src={logo7}
              alt='images'
            />
          </div>
          <div className='company-logo col  col-6 col-xl-3 '>
            <img
              className='img-fluid mx-auto d-block'
              src={logo8}
              alt='images'
            />
          </div>
        </div>
        <div className='row my-4 gap-2'>
          <div className='company-logo col  col-6 col-xl-3 '>
            <img
              className='img-fluid mx-auto d-block'
              src={logo9}
              alt='images'
            />
          </div>
          <div className='company-logo col  col-6 col-xl-3 '>
            <img
              className='img-fluid mx-auto d-block'
              src={logo10}
              alt='images'
            />
          </div>
          <div className='company-logo col  col-6 col-xl-3 '>
            <img
              className='img-fluid mx-auto d-block'
              src={logo11}
              alt='images'
            />
          </div>
          <div className='company-logo col  col-6 col-xl-3 '>
            <img
              className='img-fluid mx-auto d-block'
              src={logo12}
              alt='images'
            />
          </div>
        </div>
        <div className='row my-4 gap-2'>
          <div className='company-logo col  col-6 col-xl-3 '>
            <img
              className='img-fluid mx-auto d-block'
              src={logo13}
              alt='images'
            />
          </div>
          <div className='company-logo col  col-6 col-xl-3 '>
            <img
              className='img-fluid mx-auto d-block'
              src={logo14}
              alt='images'
            />
          </div>
          <div className='company-logo col  col-6 col-xl-3 '>
            <img
              className='img-fluid mx-auto d-block'
              src={logo15}
              alt='images'
            />
          </div>
          <div className='company-logo col  col-6 col-xl-3 '>
            <img
              className='img-fluid mx-auto d-block'
              src={logo16}
              alt='images'
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Companies;
